export const HOME_PAGE = '/';
export const ABOUT_PAGE = '/about';
export const ANAMOR_PAGE = '/anamor';
export const BOTTLE_PAGE = '/bottle';
export const CELLAR_PAGE = '/cellar';
export const CONTACT_PAGE = '/contact';
export const EVENTS_PAGE = '/events';
export const EVENT_DETAIL_PAGE = '/events/:id';
export const TOURS_BOOKING_PAGE = '/tours/booking/:id';
export const EVENTS_BOOKING_PAGE = '/events/booking/:id';
export const TOURS_BOOKING_PERSONAL_PAGE = '/tours/booking/personal/:id';
export const FACTORY_PAGE = '/factory';
export const HOLANI_PAGE = '/holani';
export const TOURS_PAGE = '/tours';
export const WINES_PAGE = '/wines';
export const PRIVACY_PAGE = '/privacy';
export const WINES_HOLANI_PAGE = '/wines/holani';
export const WINES_ANAMOR_PAGE = '/wines/anamor';
export const FACTORY_CELLARS_PAGE = '/factory/cellars';
export const FACTORY_BOTTLE_PAGE = '/factory/bottling';
export const VINEYARDS_PAGE = '/vineyards';
export const FERMENTATION_PAGE = '/fermentation';
export const TERROIR_PAGE = '/terroir';

export const ADMIN_PAGE = '/admin';
export const ADMIN_LOGIN_PAGE = `${ADMIN_PAGE}/login`;

//upload
export const ADMIN_UPLOADS_PAGE = `${ADMIN_PAGE}/uploads`;

//Menu
export const ADMIN_HOME_MENU_PAGE = `${ADMIN_PAGE}/create/menu`;

//Home admin section
export const ADMIN_HOME_SLIDER_PAGE = `${ADMIN_PAGE}/create/homeSlider`;
export const ADMIN_HOME_FACTORY_PAGE = `${ADMIN_PAGE}/create/homeFactory`;
export const ADMIN_HOME_BANNER_PAGE = `${ADMIN_PAGE}/create/homeBanner`;
export const ADMIN_HOME_HOME_TOUR_PAGE = `${ADMIN_PAGE}/create/homeTours`;
export const ADMIN_HOME_HOME_EVENT_PAGE = `${ADMIN_PAGE}/create/homeEvents`;

//factory
export const ADMIN_FACTORY_TERRIOR_PAGE = `${ADMIN_PAGE}/create/factoryTerrior`;
export const ADMIN_FACTORY_WINE_YARDS_PAGE = `${ADMIN_PAGE}/create/factoryWineYards`;
export const ADMIN_FACTORY_FERMENTATION_PAGE = `${ADMIN_PAGE}/create/factoryFermentation`;
export const ADMIN_FACTORY_CELLARS_PAGE = `${ADMIN_PAGE}/create/factoryCellars`;
export const ADMIN_FACTORY_BOTTLING_PAGE = `${ADMIN_PAGE}/create/factoryBottling`;

//brands
// export const ADMIN_BRAND_CREATE_PAGE = `${ADMIN_PAGE}/brand/create`;
export const ADMIN_BRAND_NAMES_CREATE_PAGE = `${ADMIN_PAGE}/brand/names/create`;
export const ADMIN_BRAND_NAMES_MANAGEMENT_PAGE = `${ADMIN_PAGE}/brand/names/management`;

export const ADMIN_HOME_EVENT_PAGE = `${ADMIN_PAGE}/create/homeEvent`;
export const ADMIN_HOME_TOUR_PAGE = `${ADMIN_PAGE}/create/homeTour`;

export const ADMIN_TOURS_CREATE_PAGE = `${ADMIN_PAGE}/tours/create`;

export const ADMIN_TOURS_MANAGEMENT_PAGE = `${ADMIN_PAGE}/tours/management`;
export const ADMIN_TOURS_UPDATE_PAGE = `${ADMIN_PAGE}/tours/update`;
export const ADMIN_TOURS_BOOKING_PAGE = `${ADMIN_PAGE}/tours/booking`;
export const ADMIN_EVENTS_BOOKING_PAGE = `${ADMIN_PAGE}/events/booking`;
export const ADMIN_SUBSCRIBERS_PAGE = `${ADMIN_PAGE}/subscribers`;
export const ADMIN_PRODUCT_CREATE_PAGE = `${ADMIN_PAGE}/product/create`;
export const ADMIN_PRODUCT_SHOW_PAGE = `${ADMIN_PAGE}/product/showcase`;
export const ADMIN_PRODUCT_MANAGEMENT_PAGE = `${ADMIN_PAGE}/product/management`;
export const ADMIN_PRODUCT_UPDATE_PAGE = `${ADMIN_PAGE}/product/update`;
export const ADMIN_PRODUCT_WINE_MAIN_BANNER_PAGE = `${ADMIN_PAGE}/product/wineMainBanner`;
export const ADMIN_PRODUCT_WINE_MIDDLE_BANNER_PAGE = `${ADMIN_PAGE}/product/wineMiddleBanner`;
export const ADMIN_PRODUCT_INFO_PAGE = `${ADMIN_PAGE}/product/info`;
export const ADMIN_EVENTS_CREATE_PAGE = `${ADMIN_PAGE}/events/create`;
export const ADMIN_EVENTS_CREATE_PAGE_TEXT = `${ADMIN_PAGE}/events/about/create`;
export const ADMIN_EVENTS_MANAGEMENT_PAGE = `${ADMIN_PAGE}/events/management`;
export const ADMIN_EVENTS_UPDATE_PAGE = `${ADMIN_PAGE}/events/update`;
export const ADMIN_PARTNER_CREATE_PAGE = `${ADMIN_PAGE}/partner/create`;
export const ADMIN_PARTNER_MANAGEMENT_PAGE = `${ADMIN_PAGE}/partner/management`;
export const ADMIN_PARTNER_UPDATE_PAGE = `${ADMIN_PAGE}/partner/update`;

export const ADMIN_CELLAR_CREATE_PAGE = `${ADMIN_PAGE}/cellar/create`;
export const ADMIN_CELLAR_MAIN_BANNER_PAGE = `${ADMIN_PAGE}/cellar/mainBanner`;
export const ADMIN_CELLAR_MIDDLE_BANNER_PAGE = `${ADMIN_PAGE}/cellar/middleBanner`;
export const ADMIN_CELLAR_INFO_PAGE = `${ADMIN_PAGE}/cellar/info`;
export const ADMIN_BOTTLING_CREATE_PAGE = `${ADMIN_PAGE}/bottling/create`;
export const ADMIN_BOTTLING_MAIN_BANNER_PAGE = `${ADMIN_PAGE}/bottling/mainBanner`;
export const ADMIN_BOTTLING_MIDDLE_BANNER_PAGE = `${ADMIN_PAGE}/bottling/middleBanner`;
export const ADMIN_BOTTLING_INFO_PAGE = `${ADMIN_PAGE}/bottling/info`;
export const ADMIN_ANAMOR_CREATE_PAGE = `${ADMIN_PAGE}/create/anamor`;
export const ADMIN_ANAMOR_MAIN_BANNER_PAGE = `${ADMIN_PAGE}/anamor/anamorMainBanner`;
export const ADMIN_ANAMOR_LOWER_BANNER_PAGE = `${ADMIN_PAGE}/anamor/anamorLowerBanner`;

export const ADMIN_HOLANI_CREATE_PAGE = `${ADMIN_PAGE}/create/holani`;
export const ADMIN_HOLANI_MAIN_BANNER_PAGE = `${ADMIN_PAGE}/holani/holaniMainBanner`;
export const ADMIN_HOLANI_LOWER_BANNER_PAGE = `${ADMIN_PAGE}/holani/holaniLowerBanner`;
