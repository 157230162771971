import {LazyLoadPage} from './components/lazy-load-page';
import {PageName} from './constants';

export const Page = {
    [PageName.Home]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../Home'
                    )
            }
            name={PageName.Home}
        />
    ),
    [PageName.Contact]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../Contact'
                    )
            }
            name={PageName.Contact}
        />
    ),
    [PageName.Events]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../Events'
                    )
            }
            name={PageName.Events}
        />
    ),
    [PageName.Error]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../Error'
                    )
            }
            name={PageName.Error}
        />
    ),
    [PageName.PrivacyAndPolicy]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../PrivacyAndPolicy'
                    )
            }
            name={PageName.PrivacyAndPolicy}
        />
    ),
    [PageName.Factory]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../Factory'
                    )
            }
            name={PageName.Factory}
        />
    ),
    [PageName.Tours]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../Tours'
                    )
            }
            name={PageName.Tours}
        />
    ),
    [PageName.Wines]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../Wines'
                    )
            }
            name={PageName.Wines}
        />
    ),

    // [PageName.FactoryCellars]: (
    //     <LazyLoadPage
    //         loader={() =>
    //             import(
    //                 /* webpackPrefetch: true */
    //                 /* webpackChunkName: "pages" */
    //                 /* webpackMode: "lazy" */
    //                 '../Cellar'
    //                 )
    //         }
    //         name={PageName.FactoryCellars}
    //     />
    // ),
    [PageName.LoginPage]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/auth/LoginPage'
                    )
            }
            name={PageName.LoginPage}
        />
    ),

    [PageName.Dashboard]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/dashboard/Dashboard'
                    )
            }
            name={PageName.Dashboard}
        />
    ),
    [PageName.Uploads]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/uploadesComponent/UploadsPage'
                    )
            }
            name={PageName.Uploads}
        />
    ),

    //menu admin
    [PageName.MenuImagesComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/menu/MenuImagesComponent'
                    )
            }
            name={PageName.MenuImagesComponent}
        />
    ),

    //factory admin
    [PageName.FactoryBottlingComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/factory/bottling/FactoryBottlingComponent'
                    )
            }
            name={PageName.FactoryBottlingComponent}
        />
    ),
    [PageName.FactoryTerroirComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/factory/terrior/FactoryTerroirComponent'
                    )
            }
            name={PageName.FactoryTerroirComponent}
        />
    ),
    [PageName.FactoryVineYardComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/factory/vineYard/FactoryVineYardComponent'
                    )
            }
            name={PageName.FactoryVineYardComponent}
        />
    ),
    [PageName.FactoryFermentationComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/factory/fermentation/FactoryFermentationComponent'
                    )
            }
            name={PageName.FactoryFermentationComponent}
        />
    ),
    [PageName.FactoryCellarComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/factory/cellar/FactoryCellarComponent'
                    )
            }
            name={PageName.FactoryCellarComponent}
        />
    ),

    [PageName.CreateTour]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/tours/CreateTour'
                    )
            }
            name={PageName.CreateTour}
        />
    ),
    [PageName.ToursComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/tours/ToursComponent'
                    )
            }
            name={PageName.ToursComponent}
        />
    ),

    [PageName.ToursUpdateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/tours/ToursUpdateComponent'
                    )
            }
            name={PageName.ToursUpdateComponent}
        />
    ),
    [PageName.TourBookingComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/toursBooking/ToursBooking'
                    )
            }
            name={PageName.TourBookingComponent}
        />
    ),



    [PageName.EventsBooking]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/eventsBooking/EventsBooking'
                    )
            }
            name={PageName.EventsBooking}
        />
    ),
    [PageName.SubscribersComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/subscribers/SubscribersComponent'
                    )
            }
            name={PageName.SubscribersComponent}
        />
    ),
    [PageName.EventsCreateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/events/EventsCreateComponent'
                    )
            }
            name={PageName.EventsCreateComponent}
        />
    ),
    [PageName.CreateEventText]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/events/CreateEventText'
                    )
            }
            name={PageName.CreateEventText}
        />
    ),
    [PageName.PartnerCreateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/partners/PartnerCreateComponent'
                    )
            }
            name={PageName.PartnerCreateComponent}
        />
    ),
    [PageName.EventsManagementComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/events/EventsManagementComponent'
                    )
            }
            name={PageName.EventsManagementComponent}
        />
    ),
    [PageName.EventUpdateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/events/EventUpdateComponent'
                    )
            }
            name={PageName.EventUpdateComponent}
        />
    ),
    [PageName.PartnersManagementComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/partners/PartnersManagementComponent'
                    )
            }
            name={PageName.PartnersManagementComponent}
        />
    ),
    [PageName.PartnersUpdateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/partners/PartnersUpdateComponent'
                    )
            }
            name={PageName.PartnersUpdateComponent}
        />
    ),
    [PageName.ProductCreateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/prooduct/ProductCreateComponent'
                    )
            }
            name={PageName.ProductCreateComponent}
        />
    ),
    [PageName.ProductShowCaseComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/prooduct/ProductShowCaseComponent'
                    )
            }
            name={PageName.ProductShowCaseComponent}
        />
    ),
    [PageName.ProductManagementComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/prooduct/ProductManagementComponent'
                    )
            }
            name={PageName.ProductManagementComponent}
        />
    ),
    [PageName.ProductUpdateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/prooduct/ProductUpdateComponent'
                    )
            }
            name={PageName.ProductUpdateComponent}
        />
    ),
    [PageName.Anamor]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/brand/AnamorCreateComponent'
                    )
            }
            name={PageName.Anamor}
        />
    ),
    [PageName.HolaniWines]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../HolaniWines'
                    )
            }
            name={PageName.HolaniWines}
        />
    ),
    [PageName.AnamorWines]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../AnamorWines'
                    )
            }
            name={PageName.AnamorWines}
        />
    ),
    [PageName.BrandCreateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/brand/BrandCreateComponent'
                    )
            }
            name={PageName.BrandCreateComponent}
        />
    ),

    //home admin section
    [PageName.HomeSliderComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/home/homeslider/HomeSliderComponent'
                    )
            }
            name={PageName.HomeSliderComponent}
        />
    ),
    [PageName.HomeFactoryComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/home/homefactory/HomeFactoryComponent'
                    )
            }
            name={PageName.HomeFactoryComponent}
        />
    ),
    [PageName.HomeBannerComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/home/homebanner/HomeBannerComponent'
                    )
            }
            name={PageName.HomeBannerComponent}
        />
    ),
    [PageName.HomeTourComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/home/hometour/HomeTourComponent'
                    )
            }
            name={PageName.HomeTourComponent}
        />
    ),
    [PageName.HomeEventComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/home/homeevent/HomeEventComponent'
                    )
            }
            name={PageName.HomeEventComponent}
        />
    ),
    [PageName.CellarsCreateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/Cellars/CellarsCreateComponent'
                    )
            }
            name={PageName.CellarsCreateComponent}
        />
    ),
    [PageName.BottlingCreateComponent]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/bottling/BottlingCreateComponent'
                    )
            }
            name={PageName.BottlingCreateComponent}
        />
    ),
    // [PageName.CreatingSildingBanner]: (
    //     <LazyLoadPage
    //         loader={() =>
    //             import(
    //                 /* webpackPrefetch: true */
    //                 /* webpackChunkName: "pages" */
    //                 /* webpackMode: "lazy" */
    //                 '../../admin/components/banners/CreatingSildingBanner'
    //                 )
    //         }
    //         name={PageName.CreatingSildingBanner}
    //     />
    // ),
    [PageName.CreateBanner]: (
        <LazyLoadPage
            loader={() =>
                import(
                    /* webpackPrefetch: true */
                    /* webpackChunkName: "pages" */
                    /* webpackMode: "lazy" */
                    '../../admin/components/banners/CreateBanner'
                    )
            }
            name={PageName.CreateBanner}
        />
    ),
    // [PageName.InfoComponent]: (
    //     <LazyLoadPage
    //         loader={() =>
    //             import(
    //                 /* webpackPrefetch: true */
    //                 /* webpackChunkName: "pages" */
    //                 /* webpackMode: "lazy" */
    //                 '../../admin/components/InfoComponent/InfoComponent'
    //                 )
    //         }
    //         name={PageName.InfoComponent}
    //     />
    // ),
};
