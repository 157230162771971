

export const PageName = {
  About: 'About',
  Home: 'Home',
  Anamor: 'BrandCreateComponent',
  Holani: 'BrandCreateComponent',
  Bottle: 'Bottle',
  Cellar: 'Cellar',
  Contact: 'Contact',
  Events: 'Events',
  Factory: 'Factory',
  Tours: 'Tours',
  Wines: 'Wines',
  Error: 'Error',
  Booking: 'BookingComponent',
  PrivacyAndPolicy: 'PrivacyAndPolicy',
  BookingToursPersonal: 'BookingToursPersonalComponent',
  EventDetail: 'EventDetail',
  HolaniWines: 'HolaniWines',
  AnamorWines: 'AnamorWines',

  LoginPage: 'LoginPage',

  //dashboard
  Dashboard: 'Dashboard',

  //uploads
  Uploads: 'Uploads',

  //Menu
  MenuImagesComponent:'MenuImagesComponent',

  //home admin section
  HomeSliderComponent: 'HomeSliderComponent',
  HomeFactoryComponent: 'HomeFactoryComponent',
  HomeBannerComponent: 'HomeBannerComponent',
  HomeTourComponent: 'HomeTourComponent',
  HomeEventComponent: 'HomeEventComponent',

  //factory
  FactoryBottlingComponent: 'FactoryBottlingComponent',
  FactoryTerroirComponent: 'FactoryTerroirComponent',
  FactoryVineYardComponent: 'FactoryVineYardComponent',
  FactoryFermentationComponent: 'FactoryFermentationComponent',
  FactoryCellarComponent: 'FactoryCellarComponent',

  //brands
  // BrandCreateComponent: 'BrandCreateComponent',
  // BrandNameComponent: 'BrandNameComponent',
  // BrandNameUpdateComponent: 'BrandNameUpdateComponent',

  // CreatingSildingBanner: 'CreatingSildingBanner',
  CreateBanner: 'CreateBanner',
  CreateEventText:'CreateEventText',

  SubscribersComponent: 'SubscribersComponent',
  ProductCreateComponent: 'ProductCreateComponent',
  ProductManagementComponent: 'ProductManagementComponent',
  ProductUpdateComponent: 'ProductUpdateComponent',
  ProductShowCaseComponent: 'ProductShowCaseComponent',
  EventsAbout: 'EventsAbout',
  InfoComponentFactory: 'InfoComponentFactory',
  CreateTour: 'CreateTour',
  ToursComponent: 'ToursComponent',
  ToursUpdateComponent: 'ToursUpdateComponent',
  TourBookingComponent: 'TourBookingComponent',
  EventsCreateComponent: 'EventsCreateComponent',
  PartnerCreateComponent: 'PartnerCreateComponent',
  EventsManagementComponent: 'EventsManagementComponent',
  EventUpdateComponent: 'EventUpdateComponent',
  PartnersManagementComponent: 'PartnersManagementComponent',
  PartnersUpdateComponent: 'PartnersUpdateComponent',

  CellarsCreateComponent: 'CellarsCreateComponent',
  BottlingCreateComponent: 'BottlingCreateComponent',
  InfoComponentCellar: 'InfoComponentCellar',
  BrandCreateComponent: 'BrandCreateComponent',

  EventsBooking: 'EventsBooking',

};
