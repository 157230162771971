import * as paths from './paths';

import {Page} from './Page';

class Route {
    constructor(path, element, children) {
        this.path = path;
        this.element = element;
        this.children = children;
    }
}

const routesBase = [
    new Route(paths.HOME_PAGE, Page.Home),
    new Route(paths.ABOUT_PAGE, Page.About),
    new Route(paths.CELLAR_PAGE, Page.Cellar),
    new Route(paths.CONTACT_PAGE, Page.Contact),
    new Route(paths.PRIVACY_PAGE, Page.PrivacyAndPolicy),
    new Route(paths.EVENTS_PAGE, Page.Events, [
        new Route(':id', Page.EventDetail),
    ]),
    new Route("*", Page.Error),
    // new Route("*", <Navigate to={Page.Error} replace />),
    new Route(paths.TERROIR_PAGE, Page.Factory),
    new Route(paths.VINEYARDS_PAGE, Page.Factory),
    new Route(paths.FERMENTATION_PAGE, Page.Factory),
    new Route(paths.FACTORY_PAGE, Page.Factory, [
        new Route(paths.FACTORY_CELLARS_PAGE, Page.FactoryCellars),
        new Route(paths.FACTORY_BOTTLE_PAGE, Page.FactoryBottling),
    ]),
    new Route(paths.HOLANI_PAGE, Page.HolaniWines),
    new Route(paths.ANAMOR_PAGE, Page.AnamorWines),
    new Route(paths.TOURS_PAGE, Page.Tours, [
        new Route(':id', Page.EventDetail),
        new Route(paths.TOURS_BOOKING_PAGE, Page.BookingComponent),
        new Route(
            paths.TOURS_BOOKING_PERSONAL_PAGE,
            Page.BookingToursPersonalComponent,
        ),
    ]),
    new Route(paths.WINES_PAGE, Page.Wines, [
        new Route(paths.WINES_HOLANI_PAGE, Page.Holani),
        new Route(paths.WINES_ANAMOR_PAGE, Page.Anamor),
        new Route(':id', Page.EventDetail),
    ]),
];

const routesAdmin = [
    new Route(paths.ADMIN_LOGIN_PAGE, Page.LoginPage),
    new Route(paths.ADMIN_PAGE, Page.Dashboard),
    //upload
    new Route(paths.ADMIN_UPLOADS_PAGE, Page.Uploads),
//Menu
    new Route(paths.ADMIN_HOME_MENU_PAGE, Page.MenuImagesComponent),
//Home
    new Route(paths.ADMIN_HOME_SLIDER_PAGE, Page.HomeSliderComponent),
    new Route(paths.ADMIN_HOME_FACTORY_PAGE, Page.HomeFactoryComponent),
    new Route(paths.ADMIN_HOME_BANNER_PAGE, Page.HomeBannerComponent),
    new Route(paths.ADMIN_HOME_HOME_TOUR_PAGE, Page.HomeTourComponent),
    new Route(paths.ADMIN_HOME_HOME_EVENT_PAGE, Page.HomeEventComponent),

    // factory
    new Route(paths.ADMIN_FACTORY_TERRIOR_PAGE, Page.FactoryTerroirComponent),
    new Route(paths.ADMIN_FACTORY_WINE_YARDS_PAGE, Page.FactoryVineYardComponent),
    new Route(paths.ADMIN_FACTORY_FERMENTATION_PAGE, Page.FactoryFermentationComponent),
    new Route(paths.ADMIN_FACTORY_CELLARS_PAGE, Page.FactoryCellarComponent),
    new Route(paths.ADMIN_FACTORY_BOTTLING_PAGE, Page.FactoryBottlingComponent),

    //brands
    // new Route(paths.ADMIN_BRAND_CREATE_PAGE, Page.BrandCreateComponent),
    new Route(paths.ADMIN_BRAND_NAMES_CREATE_PAGE, Page.BrandNameComponent),
    new Route(paths.ADMIN_BRAND_NAMES_MANAGEMENT_PAGE, Page.BrandNameUpdateComponent,),

    new Route(paths.ADMIN_FACTORY_FERMENTATION_PAGE, Page.CreatingSildingBanner, [
        new Route(':id', Page.CreateBanner),
    ]),
    new Route(paths.ADMIN_FACTORY_CELLARS_PAGE, Page.CreatingSildingBanner, [
        new Route(':id', Page.CreateBanner),
    ]),
    new Route(paths.ADMIN_FACTORY_BOTTLING_PAGE, Page.CreatingSildingBanner, [
        new Route(':id', Page.CreateBanner),
    ]),

    new Route(paths.ADMIN_EVENTS_BOOKING_PAGE, Page.EventsBooking),

    new Route(paths.ADMIN_TOURS_CREATE_PAGE, Page.CreateTour),

    new Route(paths.ADMIN_TOURS_MANAGEMENT_PAGE, Page.ToursComponent),
    new Route(paths.ADMIN_TOURS_UPDATE_PAGE, Page.ToursUpdateComponent),
    new Route(paths.ADMIN_TOURS_BOOKING_PAGE, Page.TourBookingComponent),
    new Route(paths.ADMIN_SUBSCRIBERS_PAGE, Page.SubscribersComponent),
    new Route(paths.ADMIN_PRODUCT_CREATE_PAGE, Page.ProductCreateComponent),
    new Route(
        paths.ADMIN_PRODUCT_MANAGEMENT_PAGE,
        Page.ProductManagementComponent,
    ),
    new Route(paths.ADMIN_PRODUCT_UPDATE_PAGE, Page.ProductUpdateComponent),
    new Route(paths.ADMIN_PRODUCT_SHOW_PAGE, Page.ProductShowCaseComponent),
    new Route(paths.ADMIN_PRODUCT_WINE_MAIN_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_PRODUCT_WINE_MIDDLE_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_PRODUCT_INFO_PAGE, Page.InfoComponent),
    new Route(paths.ADMIN_EVENTS_CREATE_PAGE, Page.EventsCreateComponent),
    new Route(paths.ADMIN_EVENTS_CREATE_PAGE_TEXT, Page.CreateEventText),
    new Route(paths.ADMIN_EVENTS_MANAGEMENT_PAGE, Page.EventsManagementComponent),
    new Route(paths.ADMIN_EVENTS_UPDATE_PAGE, Page.EventUpdateComponent),
    new Route(paths.ADMIN_PARTNER_CREATE_PAGE, Page.PartnerCreateComponent),
    new Route(
        paths.ADMIN_PARTNER_MANAGEMENT_PAGE,
        Page.PartnersManagementComponent,
    ),
    new Route(paths.ADMIN_PARTNER_UPDATE_PAGE, Page.PartnersUpdateComponent),


    new Route(paths.ADMIN_CELLAR_CREATE_PAGE, Page.CellarsCreateComponent),
    new Route(paths.ADMIN_CELLAR_MAIN_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_CELLAR_MIDDLE_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_CELLAR_INFO_PAGE, Page.InfoComponent),
    new Route(paths.ADMIN_BOTTLING_CREATE_PAGE, Page.BottlingCreateComponent),
    new Route(paths.ADMIN_BOTTLING_MAIN_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_BOTTLING_MIDDLE_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_BOTTLING_INFO_PAGE, Page.InfoComponent),
    new Route(paths.ADMIN_ANAMOR_CREATE_PAGE, Page.BrandCreateComponent),
    new Route(paths.ADMIN_ANAMOR_MAIN_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_ANAMOR_LOWER_BANNER_PAGE, Page.CreateBanner),

    new Route(paths.ADMIN_HOLANI_CREATE_PAGE, Page.BrandCreateComponent),
    new Route(paths.ADMIN_HOLANI_MAIN_BANNER_PAGE, Page.CreateBanner),
    new Route(paths.ADMIN_HOLANI_LOWER_BANNER_PAGE, Page.CreateBanner)
];

export const routes = [...routesBase, ...routesAdmin];
